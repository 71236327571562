import axios from 'axios'

export function postComments (json) {
  return axios({
    method: 'POST',
    url: '/api/v1/comments',
    data: json
  })
    .catch(function (error) {
      console.error(error)
      throw error
    })
}
