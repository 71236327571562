/* eslint eqeqeq: 0 */
import $ from 'jquery'
import ApplicantCard from './ApplicantCard'
import { findModal } from '../applicant'
import tinymce from 'tinymce'
import { getComments as getCycleComments, getCycleStatuses } from '../api/cycle_repository'
import { getComments as getHiringViewComments, getHiringViewDetails } from '../api/hiring_view_repository'
import dayjs from 'dayjs'
import { postComments } from '../api/comment_repository'
import { showNotification } from '@mantine/notifications'

class DetailView {
  init () {
    this.widget = $('div#detail-view')
    this.cycleId = $('div#detail-view').attr('data-cycleid')
    this.hiringViewId = $('div#detail-view').attr('data-hiring-view-id')
    this.applicantCard = new ApplicantCard()
    this.autofocus = false
    this.columnCookiePrefix = 'ColumnPreference-' + this.cycleId + '-'
    this.isAdmin = document.getElementById('detail-view').hasAttribute('data-has-admin-access')
    this.loadHiddenColumnPreferences()
    this.loadStatuses()
    this.bindUIActions()

    if (this.isAdmin) {
      this.checkUpdateProgress(false)
    }
  }

  loadHiddenColumnPreferences () {
    this.originalCookie = getMatchingCookies(this.columnCookiePrefix)
    this.changedColumns = {}
    this.defaultHiddenColumns = {}
    this.parseCookieToOriginallyHiddenColumns()
    this.checkAnyColumnsChanged()
  }

  parseCookieToOriginallyHiddenColumns () {
    for (const columnId in this.originalCookie) {
      if (Object.prototype.hasOwnProperty.call(this.originalCookie, columnId)) {
        const hiddenOrNot = this.originalCookie[columnId]
        const columnSelection = $('#detail-view .data-view table [data-column="' + columnId + '"]')
        if (hiddenOrNot === 'true') {
          if (columnSelection.hasClass('hidden')) {
            this.defaultHiddenColumns[columnId] = 'true'
          } else {
            this.defaultHiddenColumns[columnId] = 'false'
            this.changedColumns[columnId] = 'false'
          }
          columnSelection.fadeOut()
          columnSelection.addClass('hidden')
          const selectedCheckbox = $('input.hide-column[data-id="' + columnId + '"]')
          selectedCheckbox.prop('checked', false)
        } else if (hiddenOrNot === 'false') {
          if (columnSelection.hasClass('hidden')) {
            this.changedColumns[columnId] = 'true'
            this.defaultHiddenColumns[columnId] = 'true'
            columnSelection.fadeIn()
            columnSelection.removeClass('hidden')
            const selectedCheckbox = $('input.hide-column[data-id="' + columnId + '"]')
            selectedCheckbox.prop('checked', true)
          } else {
            this.defaultHiddenColumns[columnId] = 'false'
          }
        }
      }
    }
  }

  parseCookieToHiddenColumns () {
    for (const columnId in this.originalCookie) {
      if (Object.prototype.hasOwnProperty.call(this.originalCookie, columnId)) {
        const hiddenOrNot = this.originalCookie[columnId]
        const columnSelection = $('#detail-view .data-view table [data-column="' + columnId + '"]')
        if (hiddenOrNot === 'true') {
          columnSelection.fadeOut()
          columnSelection.addClass('hidden')
          const selectedCheckbox = $('input.hide-column[data-id="' + columnId + '"]')
          selectedCheckbox.prop('checked', false)
        } else if ((hiddenOrNot === 'false') && (columnSelection.hasClass('hidden'))) {
          columnSelection.fadeIn()
          columnSelection.removeClass('hidden')
          const selectedCheckbox = $('input.hide-column[data-id="' + columnId + '"]')
          selectedCheckbox.prop('checked', true)
        }
      }
    }
  }

  setColumnHideStateAndCompareToOriginal (columnId, hideState) {
    if (Object.prototype.hasOwnProperty.call(this.defaultHiddenColumns, columnId)) {
      const originalHideState = this.defaultHiddenColumns[columnId]
      if (originalHideState != hideState.toString()) {
        this.changedColumns[columnId] = originalHideState
      } else {
        delete this.changedColumns[columnId]
      }
    } else {
      const originalHideState = (!hideState).toString()
      this.defaultHiddenColumns[columnId] = originalHideState
      this.changedColumns[columnId] = originalHideState
    }
    setCookie(this.columnCookiePrefix + columnId, hideState, 365)
    this.checkAnyColumnsChanged()
  }

  checkAnyColumnsChanged () {
    let anyChanged = false
    for (const columnId in this.changedColumns) {
      if (Object.prototype.hasOwnProperty.call(this.changedColumns, columnId)) {
        anyChanged = true
        break
      }
    }
    const filterColumnsSelection = $('a.filter-columns')
    const filtersDisplayingChanged = filterColumnsSelection.hasClass('has-columns-hidden')
    if ((anyChanged) && (!filtersDisplayingChanged)) {
      filterColumnsSelection.addClass('has-columns-hidden')
    } else if ((!anyChanged) && (filtersDisplayingChanged)) {
      filterColumnsSelection.removeClass('has-columns-hidden')
    }
  }

  bindUIActions () {
    const self = this
    this.applicantCard.init()

    $(document).on('click', '[data-column="discussion"] div.cell-comments-container *:not(p)', function () {
      const id = $(this).parents('tr').attr('data-row-id')
      if (self.applicantCard.applicantId === id) {
        if (!self.applicantCard.isOpen()) {
          self.selectApplicant(id)
        }
      } else {
        self.selectApplicant(id)
        self.autofocus = true
      }
    })

    $(document).on('click', '#detail-view .data-view tr td span.status-cell-value', function () {
      const isGrantedEdit = $('#detail-view').attr('data-is-granted-edit')

      if (isGrantedEdit) {
        const appId = $(this).parents('tr').attr('data-row-id')
        const cycleId = $(this).parents('tr').attr('data-cycle-id')
        const currentValue = $(this).text()
        $('select.status-select').each(function (index, element) {
          $(element).replaceWith('<span class="status-cell-value status-cell-editable">' + $(element).find('option:selected').text() + '</span>')
        })
        $(this).replaceWith(self.getStatusDropdown(cycleId, appId, currentValue))
      }
    })

    $(document).on('change', 'select[name="edit-status"]', function () {
      const value = $(this).find('option:selected').text()
      const statusName = $(this).find('option:selected').val()
      const appId = $(this).attr('data-appid')
      const cycleId = $(this).closest('tr').attr('data-cycle-id')
      let status

      if (self.cycleId !== undefined) {
        status = self.findStatus(statusName)
      } else {
        for (const cycle of self.hiringViewDetails.cycles) {
          if (parseInt(cycle.id) === parseInt(cycleId)) {
            status = cycle.status_set.statuses.find(s => s.name === statusName)
            break
          }
        }
      }

      if (status.tier.id === 2) {
        self.showHiredDateModal(status, [appId])
      } else {
        $(this).replaceWith('<span class="status-cell-value status-cell-editable">' + value + '</span>')
        self.saveStatuses([appId], statusName)
        self.updateApplicantStatusColor(appId, status.tier.id)
      }
    })

    $(document).on('click', '#detail-view .data-view table tbody tr', function () {
      const id = $(this).attr('data-row-id')
      self.selectApplicant(id)
    })

    $(document).on('change', '#detail-view .data-view table thead th[data-column="actions"] input.hide-column', function () {
      const id = $(this).data('id')
      if ($(this).prop('checked')) {
        self.setColumnHideStateAndCompareToOriginal(id, false)
        $('#detail-view .data-view table [data-column="' + id + '"]').fadeIn().removeClass('hidden')
      } else {
        self.setColumnHideStateAndCompareToOriginal(id, true)
        $('#detail-view .data-view table [data-column="' + id + '"]').fadeOut().addClass('hidden')
      }
    })

    $(document).on('click', '#detail-view .data-view tr :not(td)', function (e) {
      e.stopPropagation()
    })

    $(document).on('click', '[data-column="discussion"] div.cell-comments-container li.pinned-comment-item p', function (e) {
      let text = ''
      if (e.ctrlKey) {
        $(this).parents('ul').find('li').each(function (i, e) {
          text += $(e).find('p').text() + '\n'
        })
      } else {
        text = $(this).text()
      }

      navigator.clipboard.writeText(text)
      showNotification({
        message: 'Copied text: "' + text + '"',
        color: 'blue',
        autoClose: true
      })

      e.stopPropagation()
    })

    window.addEventListener('appcard:open', function () {
      self.widget.addClass('condensed')
      // $(document).trigger('tinymce:init')
    })

    window.addEventListener('appcard:close', function () {
      self.closeCard()
    })

    const refresh = () => self.refreshComments()

    window.addEventListener('discussion:comment-posted', refresh)
    window.addEventListener('discussion:comment-updated', refresh)
    window.addEventListener('discussion:comment-deleted', refresh)

    $(document).on('dataview:loaded', function () {
      self.refreshComments()
      self.parseCookieToHiddenColumns()
      self.loadFlags()
    })

    $(document).on('tinymce:init', function () {
      if (self.autofocus) {
        self.autofocus = false
      }
    })

    $(document).on('click', '.closeModal', function () {
      $(this).parents().find('.reveal-modal').foundation('reveal', 'close')
    })

    $(document).on('click', '#save-hired-date', function () {
      const appIds = $(this).parent().data('appIds')
      const status = $(this).parent().data('status')
      const hiredDate = $('input[name="hired-date"]').val()
      self.saveStatuses(appIds, status.name, hiredDate, true)
      $(this).parents().find('.reveal-modal').foundation('reveal', 'close')
    })

    $(document).on('click', '#cancel-hired-date', function () {
      self.cancelHiredDate()
    })

    $(document).on('click', 'div#bulk-actions a#change_statuses', function () {
      const modal = findModal()
      modal.html(
        '<div class="row">' +
          '<div class="small-4 columns">' +
            '<h2>Bulk Edit Statuses</h2>' +
            self.getStatusDropdown() +
            '<div class="row">' +
              '<div class="small-12 columns">' +
                '<a id="saveBulkStatuses" class="button tiny radius success">Save</a>' +
                '<a id="cancelBulkStatuses" class="button tiny radius secondary closeModal">Cancel</a>' +
              '</div>' +
            '</div>' +
          '</div>' +
        '</div>'
      )

      modal.foundation('reveal', 'open')
    })

    $(document).on('click', 'div#bulk-actions a#bulk_comment', function () {
      const modal = findModal()
      modal.html('<div class="row">' +
      '<div class="small-4 columns">' +
      '<h2>Bulk Comment</h2>' +
      '<textarea name="bulk_comment_text"></textarea>' +
      '<label for="bulk_comment_pinned">Pinned</label>' +
      '<input id="bulk_comment_pinned" type="checkbox" checked="checked" />' +
      '<div class="row">' +
      '<div class="small-12 columns">' +
      '<a id="saveBulkComment" class="button tiny radius success">Save</a>' +
      '<a id="cancelBulkComment" class="button tiny radius secondary closeModal">Cancel</a>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>')

      tinymce.init({
        selector: 'textarea[name="bulk_comment_text"]',
        menubar: false,
        toolbar: false,
        branding: false,
        elementpath: false,
        statusbar: false,
        min_height: 60,
        height: 60,
        autoresize: true,
        resize: false,
        contextmenu: 'cut copy paste | bold italic underline',
        contextmenu_never_use_native: true,
        invalid_elements: 'a,head,button,script,style,input,form,select,link,title,meta,iframe,noscript,canvas'
      })
      modal.foundation('reveal', 'open')
    })

    $(document).on('click', 'a#saveBulkStatuses', function () {
      const appIds = $('input.highlightedRows').map(function () {
        return $(this).val()
      }).toArray()
      const value = $('select[name="bulk-edit-status"]').find('option:selected').val()
      let status

      if (self.cycleId !== undefined) {
        status = self.findStatus(value)
      } else {
        if (self.hiringViewDetails.cycles.length > 0) {
          status = self.hiringViewDetails.cycles[0].status_set.statuses.find(s => s.name === value)
        }
      }

      if (status.tier.id === 2) {
        self.showHiredDateModal(status, appIds)
      } else {
        self.saveStatuses(appIds, value, null, true)
        $('a#cancelBulkStatuses').trigger('click')
      }
    })

    $(document).on('click', 'a#saveBulkComment', function () {
      const appIds = $('input.highlightedRows').map(function () {
        return $(this).val()
      }).toArray()
      const text = tinymce.activeEditor.getContent()
      const pinned = $('input#bulk_comment_pinned').prop('checked')

      if (text.length > 0) {
        const data = []

        for (const applicantId of appIds) {
          data.push({
            applicant: applicantId,
            content: text,
            pinned: pinned ? 1 : 0
          })
        }

        postComments(data).then(() => {
          $('a#cancelBulkComment').trigger('click')

          const event = new Event('applicant:updated')
          window.dispatchEvent(event)

          $('input[name="select-all"]').prop('checked', false)
          $('input.row-multi-selector').prop('checked', false)
          $('div#bulk-actions').addClass('disabled')
          $('#detail-view .data-view').trigger('row:highlight', [false])
        })
      }
    })
  }

  selectApplicant (id) {
    $('tr.selected').removeClass('selected')
    if (this.applicantCard.selectApplicant(id)) {
      $('tr[data-row-id="' + id + '"]').addClass('selected')
    } else {
      this.closeCard()
    }
  }

  closeCard () {
    this.widget.removeClass('condensed')
    this.autofocus = false
    $('tr.selected').removeClass('selected')
  }

  loadStatuses () {
    if (this.cycleId !== undefined) {
      getCycleStatuses(this.cycleId).then((data) => {
        this.cycleStatusSet = data.items
      })
    } else {
      getHiringViewDetails(this.hiringViewId).then((data) => {
        this.hiringViewDetails = data
      })
    }
  }

  getStatusDropdown (cycleId = null, appId = null, currentValue = null) {
    let statusSelect = '<select class="status-select" name="bulk-edit-status"></select>'

    if (appId !== null) {
      statusSelect = $(statusSelect).attr('data-appid', appId).attr('name', 'edit-status')
    }

    if (currentValue !== null) {
      statusSelect = $(statusSelect.data('currentvalue', currentValue))
    }

    if (this.cycleId !== undefined) {
      for (const status of this.cycleStatusSet) {
        const option = '<option value="' + status.name + '" ' + (status.name === currentValue ? 'selected' : '') + '>' + status.name + '</option>'
        statusSelect = $(statusSelect).append(option)
      }
    } else {
      const statuses = []

      if (cycleId !== null) {
        for (const cycle of this.hiringViewDetails.cycles) {
          if (parseInt(cycle.id) === parseInt(cycleId)) {
            for (const status of cycle.status_set.statuses) {
              statuses.push(status.name)
            }
          }
        }
      } else {
        if (this.hiringViewDetails.cycles.length > 0) {
          for (const status of this.hiringViewDetails.cycles[0].status_set.statuses) {
            statuses.push(status.name)
          }
        }
      }

      for (const status of statuses) {
        const option = '<option value="' + status + '" ' + (status === currentValue ? 'selected' : '') + '>' + status + '</option>'
        statusSelect = $(statusSelect).append(option)
      }
    }

    return $(statusSelect).prop('outerHTML')
  }

  saveStatuses (appIds, currentValue, hiredDate = null, doRefresh = false) {
    $.ajax({
      url: '/api/v1/statuses/applicants/bulkedit',
      type: 'POST',
      data: { 'status-name': currentValue, 'applicant-ids': appIds, 'hired-date': hiredDate },
      success: function () {
        if (doRefresh) {
          $('a.reset-sorting').trigger('click')
        }
        const event = new Event('detailview:updatecard')
        window.dispatchEvent(event)
      }
    })
  }

  cancelHiredDate () {
    const self = this
    const modal = findModal()
    const appId = $('#hired-date-actions').data('appId')
    const status = $('tr[data-appid="' + appId + '"] td[data-column="status"] select').attr('data-currentvalue')
    self.restoreApplicantStatusCell(appId, status)
    modal.foundation('reveal', 'close')
  }

  showHiredDateModal (status, applicantIds = []) {
    const modal = findModal()
    const date = dayjs().format('YYYY-MM-DD')

    modal.html(
      '<div class="row">' +
      '<div class="small-12 columns">' +
      '<h4>Please select the hire date</h4>' +
      '</div>' +
      '<div class="small-12 medium-8 large-6 columns">' +
      '<input type="date" name="hired-date" value="' + date + '"/>' +
      '</div>' +
      '<div id="hired-date-actions" class="small-12 columns">' +
      '<a id="save-hired-date" class="tiny button radius success" style="margin-right: 10px">Save</a>' +
      '<a id="cancel-hired-date" class="tiny button radius secondary">Cancel</a>' +
      '</div>' +
      '</div>')

    $('#hired-date-actions').data('appIds', applicantIds)
    $('#hired-date-actions').data('status', status)

    modal.foundation('reveal', 'open')
  }

  refreshComments () {
    const self = this

    if (this.cycleId !== undefined) {
      getCycleComments(this.cycleId, { pinned: 1, limit: 0 }).then(response => {
        $('td[data-column="discussion"] div.cell-comments-container ul').empty()
        for (const comment of response.items) {
          const appId = comment.applicant.id
          const commentList = self.widget.find('table tbody tr[data-row-id="' + appId + '"] td[data-column="discussion"] div.cell-comments-container ul')
          if (commentList.find('li[data-id="' + comment.id + '"]').length === 0) {
            $(commentList).append('<li class="pinned-comment-item" data-id="' + comment.id + '">' + comment.content + '</li>')
          }
        }
      })
    } else {
      getHiringViewComments(this.hiringViewId, { pinned: 1, limit: 0 }).then(response => {
        $('td[data-column="discussion"] div.cell-comments-container ul').empty()
        for (const comment of response.items) {
          const appId = comment.applicant.id
          const commentList = self.widget.find('table tbody tr[data-row-id="' + appId + '"] td[data-column="discussion"] div.cell-comments-container ul')
          if (commentList.find('li[data-id="' + comment.id + '"]').length === 0) {
            $(commentList).append('<li class="pinned-comment-item" data-id="' + comment.id + '">' + comment.content + '</li>')
          }
        }
      })
    }
  }

  updateApplicantStatusColor (applicantId, tierId) {
    switch (tierId) {
      case 1:
        $('tr[data-row-id="' + applicantId + '"]').css('background-color', 'inherit').removeClass('hired')
        break
      case 2:
        $('tr[data-row-id="' + applicantId + '"]').css('background-color', '#ADEBAD')
        break
      case 3:
        $('tr[data-row-id="' + applicantId + '"]').css('background-color', '#E0E0E0').removeClass('hired')
        break
    }
  }

  restoreApplicantStatusCell (applicantId, statusName) {
    const statusCell = $('tr[data-row-id="' + applicantId + '"]').children('td[data-column="status"]')
    statusCell.html('<span class="status-cell-value status-cell-editable">' + statusName + '</span>')
  }

  loadFlags () {
    const self = this
    const ids = []

    $('.flag').each(function () {
      const id = $(this).parents('tr').data('rowId')
      ids.push(id)
    })

    if (ids.length === 0) {
      return
    }

    $.get('api/v1/cycles/' + self.cycleId + '/applicant_flags?ids[]=' + ids.join('&ids[]='), function (data) {
      let count = 0
      const flags = data.data

      $('.flag').each(function () {
        const flag = flags[count++]
        $(this).find('i').remove()
        $(this).append(flag.count)

        if (flag.count > 0) {
          $(this).wrap('<a class="flag-link" title="Click for details"></a>')
        }
      })
    })
  }

  checkUpdateProgress (previous) {
    if (this.cycleId !== undefined) {
      const self = this
      $.ajax({
        url: 'api/v1/update/cycles/' + self.cycleId + '/info',
        type: 'GET',
        dataType: 'json',
        success: function (data) {
          if (data.isUpdating) {
            $('#update-cycle-info').css('display', 'block')
            if (data.currentStep > 0) {
              $('#update-cycle-info > span > p').text('Updating cycle: Step ' + data.currentStep + '/' + data.updateSteps)
            }
            setTimeout(function () {
              self.checkUpdateProgress(true)
            }, 10000)
          } else if (previous) {
            $('#update-cycle-info > span > p').text('Cycle update has ended!')
            $('#update-cycle-info > span > i').removeClass('fa-refresh fa-spin')
            $('#update-cycle-info > span > i').addClass('fa-check')
            $('#update-cycle-info').css('background', '#12b886')
            $('#update-cycle-info').css('color', '#FFFFFF')
            setTimeout(location.reload(), 1000)
          }
        },
        error: function () {
        }
      })
    }
  }

  findStatus (name) {
    return this.cycleStatusSet.find(s => s.name === name)
  }
}

$(document).ready(function () {
  if ($('div#applicant-card.detail').length > 0) {
    const detailView = new DetailView()
    detailView.init()
  }
})

function getMatchingCookies (matchName) {
  const matchingCookies = {}
  const endName = '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(matchName) == 0) {
      const cStartIndex = c.indexOf(endName)
      matchingCookies[c.substring(matchName.length, cStartIndex)] = c.substring(cStartIndex + 1, c.length)
    }
  }
  return matchingCookies
}

function setCookie (cname, cvalue, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}
